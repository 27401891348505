@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0px;
  padding: 0px;
 }
 
 body {
   margin: 0;
   line-height: 1.2;
  
 }

 .ant-select-selection-item{
  font-size: 12px !important;
 }

 .ant-select-selector{
  height: 28px !important;
 }

 .ant-picker-input >input:placeholder-shown {
    font-size: 12.5px !important;
    color:rgb(67, 67, 67) !important;
    padding: 1px 0px !important;

 }

 .ant-picker-input >input{
  font-size: 12px !important;
  padding: 1px 0px !important;
 }

 .ant-drawer-body{
  padding: 0px !important;
 }